import Vue from 'vue'
import App from './App'
import Vant from 'vant'
import Element from "element-ui";
import 'vant/lib/index.css'
import '@/utils/rem.js'

Vue.config.productionTip = false
import router from './router'
import store from './store'
Vue.use(Vant)
Vue.use(Element);            

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
