import request from '@/utils/request'

export function userLogin(id, psw) {
  const data = {
    id,
    psw
  }
  return request({
    url: '/index/userLogin',
    method: 'post',
    data
  })
}

export function reginew(data) {
  return request({
    url: '/index/reginew',
    method: 'post',
    data
  })
}

export function exitLogin() {
  return request({
    url: '/index/exitLogin',
    method: 'get',
  })
}

export function queryJoinPlayerRoom() {
  return request({
    url: '/index/queryJoinPlayerRoom',
    method: 'get',
  })
}

export function queryJoinPlayerUser() {
  return request({
    url: '/index/queryJoinPlayerUser',
    method: 'get',
  })
}


export function joinTheGame(data) {
  return request({
    url: '/index/joinTheGame',
    method: 'post',
    data
  })
}

export function exitTheGame(data) {
  return request({
    url: '/index/exitTheGame',
    method: 'post',
    data
  })
}

export function roominUser(data) {
  return request({
    url: '/index/roominUser',
    method: 'post',
    data
  })
}

export function gameSeat(data) {
  return request({
    url: '/index/gameSeat',
    method: 'post',
    data
  })
}

export function chageGameSeat(data) {
  return request({
    url: '/index/chageGameSeat',
    method: 'post',
    data
  })
}

export function createNewGame(data) {
  return request({
    url: '/index/createNewGame',
    method: 'post',
    data
  })
}

export function queryJoinPlayernew(data) {
  return request({
    url: '/index/queryJoinPlayernew',
    method: 'post',
    data
  })
}

export function confirmIdentity(data) {
  return request({
    url: '/index/confirmIdentity',
    method: 'post',
    data
  })
}

export function seatInfo(data) {
  return request({
    url: '/index/seatInfo',
    method: 'post',
    data
  })
}

export function hidePlayer(data) {
  return request({
    url: '/index/hidePlayer',
    method: 'post',
    data
  })
}

export function updateWinnerScore(data) {
  return request({
    url: '/index/updateWinnerScore',
    method: 'post',
    data
  })
}

export function gameStart(data) {
  return request({
    url: '/index/gameStart',
    method: 'post',
    data
  })
}

export function queryGameStat(data) {
  return request({
    url: '/index/queryGameStat',
    method: 'post',
    data
  })
}

export function startVote(data) {
  return request({
    url: '/index/startVote',
    method: 'post',
    data
  })
}

export function queryIfStartVote(data) {
  return request({
    url: '/index/queryIfStartVote',
    method: 'post',
    data
  })
}

export function queryExistPlayer(data) {
  return request({
    url: '/index/queryExistPlayer',
    method: 'post',
    data
  })
}

export function submitVote(data) {
  return request({
    url: '/index/submitVote',
    method: 'post',
    data
  })
}

export function endVote(data) {
  return request({
    url: '/index/endVote',
    method: 'post',
    data
  })
}

export function adminlogin(data) {
  return request({
    url: '/index/adminlogin',
    method: 'post',
    data
  })
}

export function userDeny(data) {
  return request({
    url: '/index/userDeny',
    method: 'post',
    data
  })
}

export function queryRankingData(data) {
  return request({
    url: '/index/queryRankingData',
    method: 'post',
    data
  })
}

export function chageUsername(data) {
  return request({
    url: '/index/chageUsername',
    method: 'post',
    data
  })
}

export function userInfo(data) {
  return request({
    url: '/index/userInfo',
    method: 'post',
    data
  })
}

export function voteInfo(data) {
  return request({
    url: '/index/voteInfo',
    method: 'post',
    data
  })
}

export function endGame(data) {
  return request({
    url: '/index/endGame',
    method: 'post',
    data
  })
}

export function allowUseSkill(data) {
  return request({
    url: '/index/allowUseSkill',
    method: 'post',
    data
  })
}

export function disAllowUseSkill(data) {
  return request({
    url: '/index/disAllowUseSkill',
    method: 'post',
    data
  })
}

export function queryGameLiveData(data) {
  return request({
    url: '/index/queryGameLiveData',
    method: 'post',
    data
  })
}

export function gameList(data) {
  return request({
    url: '/index/gameList',
    method: 'post',
    data
  })
}

export function usersList(data) {
  return request({
    url: '/index/usersList',
    method: 'post',
    data
  })
}

export function updatePrice(data) {
  return request({
    url: '/index/updatePrice',
    method: 'post',
    data
  })
}

export function updateSaTime(data) {
  return request({
    url: '/index/updateSaTime',
    method: 'post',
    data
  })
}

export function delsetting(data) {
  return request({
    url: '/index/delsetting',
    method: 'post',
    data
  })
}



//技能
export function zhentanSkill(data) {
  return request({
    url: '/index/zhentanSkill',
    method: 'post',
    data
  })
}

export function binyiyuanSkill(data) {
  return request({
    url: '/index/binyiyuanSkill',
    method: 'post',
    data
  })
}

export function chaonenglizheSkill(data) {
  return request({
    url: '/index/chaonenglizheSkill',
    method: 'post',
    data
  })
}

export function tonglingzheSkill(data) {
  return request({
    url: '/index/tonglingzheSkill',
    method: 'post',
    data
  })
}

export function zhuizongzheSkill(data) {
  return request({
    url: '/index/zhuizongzheSkill',
    method: 'post',
    data
  })
}

export function jingzhangSkill(data) {
  return request({
    url: '/index/jingzhangSkill',
    method: 'post',
    data
  })
}

export function guanniaozheSkill(data) {
  return request({
    url: '/index/guanniaozheSkill',
    method: 'post',
    data
  })
}

export function cikeSkill(data) {
  return request({
    url: '/index/cikeSkill',
    method: 'post',
    data
  })
}

export function wuyaSkill(data) {
  return request({
    url: '/index/wuyaSkill',
    method: 'post',
    data
  })
}

export function sendIdentity(data) {
  return request({
    url: '/index/sendIdentity',
    method: 'post',
    data
  })
}

export function jiandieSkill(data) {
  return request({
    url: '/index/jiandieSkill',
    method: 'post',
    data
  })
}

export function jingyuzheSkill(data) {
  return request({
    url: '/index/jingyuzheSkill',
    method: 'post',
    data
  })
}

export function jianyuzhangSkill(data) {
  return request({
    url: '/index/jianyuzhangSkill',
    method: 'post',
    data
  })
}

export function gaomizheSkill(data) {
  return request({
    url: '/index/gaomizheSkill',
    method: 'post',
    data
  })
}

export function shushiSkill(data) {
  return request({
    url: '/index/shushiSkill',
    method: 'post',
    data
  })
}

export function zhenyingeSkill(data) {
  return request({
    url: '/index/zhenyingeSkill',
    method: 'post',
    data
  })
}

export function zuijiuyaSkill(data) {
  return request({
    url: '/index/zuijiuyaSkill',
    method: 'post',
    data
  })
}

export function baobiaoSkill(data) {
  return request({
    url: '/index/baobiaoSkill',
    method: 'post',
    data
  })
}

export function baozhawangSkill(data) {
  return request({
    url: '/index/baozhawangSkill',
    method: 'post',
    data
  })
}

export function showAnotherLover(data) {
  return request({
    url: '/index/showAnotherLover',
    method: 'post',
    data
  })
}

export function detectIfSelfPrison(data) {
  return request({
    url: '/index/detectIfSelfPrison',
    method: 'post',
    data
  })
}

export function detectIfSelfBantalk(data) {
  return request({
    url: '/index/detectIfSelfBantalk',
    method: 'post',
    data
  })
}

//

export function editUserInfo(data) {
  return request({
    url: '/index/editUserInfo',
    method: 'post',
    data
  })
}

export function lookDeny(data) {
  return request({
    url: '/index/lookDeny',
    method: 'post',
    data
  })
}

export function queryWinnerScore(data) {
  return request({
    url: '/index/queryWinnerScore',
    method: 'post',
    data
  })
}

export function chageDeny(data) {
  return request({
    url: '/index/chageDeny',
    method: 'post',
    data
  })
}

export function queryUserBill(data) {
  return request({
    url: '/index/queryUserBill',
    method: 'post',
    data
  })
}

export function checkoutUserBill(data) {
  return request({
    url: '/index/checkoutUserBill',
    method: 'post',
    data
  })
}

export function getSubject(data) {
  return request({
    url: '/index/getSubject',
    method: 'post',
    data
  })
}

export function determineSubject(data) {
  return request({
    url: '/index/determineSubject',
    method: 'post',
    data
  })
}

export function linchiniaoSkill(data) {
  return request({
    url: '/index/linchiniaoSkill',
    method: 'post',
    data
  })
}