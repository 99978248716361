import axios from 'axios'
// import store from '@/store'
import Qs from 'qs'
const baseUrl = process.env.VUE_APP_BASE_URL
// create an axios instance http://10.200.55.112:8090/gxdzjzb/doc.html
// http://niy5zx.natappfree.cc/gxdzjzb/wx/front/project/getOpenId
const service = axios.create({
  baseURL: baseUrl,// 'http://niy5zx.natappfree.cc/',//,//'http://10.200.55.112:8090/' 'https://www.househrb.com/',// 'http://frp.yuecin.com:8090/',// 'http://10.200.55.112:8090/', 'https://www.househrb.com/'// process.env.BASE_API, // api的base_url
  timeout: 60000, // request timeout 会不会太短了
  headers: {
    'Content-Type': 'application/json;charset=UTF8',
    'Accept': 'application/json'
  },
  dataType: 'JSON',
  transformRequest: [function (data) {
    data = JSON.stringify(data)
    return data
  }],
  paramsSerializer: function (params) {
    return Qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

// request interceptor
service.interceptors.request.use(config => {
  config.baseURL = baseUrl//'http://niy5zx.natappfree.cc/', //'http://yd.hrbonline365.com/', //process.env.BASE_API
  // Do something before request is sent
  // if (store.getters.token) {
  //   config.headers['Authorization'] = 'Bearer ' + getToken()
  // }
  config.headers['Content-Type'] = 'application/json;charset=UTF8'
  config.headers['Accept'] = 'application/json'
  config.headers['token'] = localStorage.getItem('token') ? localStorage.getItem('token') : ""
  return config
}, error => {
  // Do something with request error
  Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
  // response => response.data,
  response => {
    if (response.status === 200) {
      const res = response.data
      return res
    } else {
      return Promise.reject('error')
    }
  },
  /**
   * 下面的注释为通过在response里，自定义code来标示请求状态
   * 当code返回如下情况则说明权限有问题，登出并返回到登录页
   * 如想通过xmlhttprequest来状态码标识 逻辑可写在下面error中
   * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
   */
  error => {
    // Message({
    //   message: '网络出现问题，请刷新浏览器！',
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  })

export default service
