import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/startGame',
      name: 'startGame',
      component: () => import('@/views/startGame')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/register')
    },
    {
      path: '/gameHall',
      name: 'gameHall',
      component: () => import('@/views/gameHall')
    },
    {
      path: '/selectSeat',
      name: 'selectSeat',
      component: () => import('@/views/selectSeat')
    },
    {
      path: '/vote',
      name: 'vote',
      component: () => import('@/views/vote')
    },
    {
      path: '/identity',
      name: 'identity',
      component: () => import('@/views/identity')
    },
    {
      path: '/league',
      name: 'league',
      component: () => import('@/views/league')
    },
    {
      path: '/mine',
      name: 'mine',
      component: () => import('@/views/mine')
    },
    {
      path: '/bill',
      name: 'bill',
      component: () => import('@/views/bill')
    },
    {
      path: '/votePage',
      name: 'votePage',
      component: () => import('@/views/votePage')
    },
    {
      path: '/voteResult',
      name: 'voteResult',
      component: () => import('@/views/voteResult')
    },
    {
      path: '/map',
      name: 'map',
      component: () => import('@/views/map')
    },
    {
      path: '/pcPage',
      name: 'pcPage',
      component: () => import('@/views/pcPage')
    },
    {
      path: '/pcLogin',
      name: 'pcLogin',
      component: () => import('@/views/pcLogin')
    },
    {
      path: '/problem',
      name: 'problem',
      component: () => import('@/views/problem')
    },
  ]
})

router.beforeEach((to, from, next) => {
  if (to.path == '/') {
    next({
      path: '/startGame'
    })
  } else {
    next()
  }
})

export default router
