import { userLogin } from '@/api/interface';

const user = {
  state: {
    user: ''
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user
    }
  },

  actions: {
    // 用户名登录
    Login({ commit }, userInfo) {
      commit('SET_USER', userInfo)
      const id = userInfo.userName.trim();
      const psw = userInfo.passWord;
      return new Promise((resolve, reject) => {
        userLogin(id, psw)
          .then((res) => {
            // setToken(res.token);
            // commit('SET_TOKEN', res.token);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 登出
    // LogOut({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     loginOut({ token: state.token }).then((response) => {
    //       commit('SET_USER', '')
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_USER', '')
        resolve()
      })
    }
  }
}

export default user
